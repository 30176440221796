/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
		  // JavaScript to be fired on all pages:
	  	var positiveMargin = false;
	  	$("button.navbar-toggle").click(function() {
	  		if (!positiveMargin) {
	  			var marginTop = "15px";
	  	        positiveMargin = true;
	  		}
	  		else {
	  	       	var marginTop = "-40px";
	  	       	positiveMargin = false;
	  		}
	  		$(".wrap .content").animate({
	  			marginTop: marginTop
	  		}, {
	  			duration: 1000,
	  			specialEasing: {
	  				marginTop: 'easeOutBounce'
	  			},
	  			complete: function () {
	  			}
	  		});
	  	});
		 // Header size Animation
  		$('.header-container').data('size','big');
  		$('img.site-logo').data('size','big');
	  	$(window).scroll(function(){
			
			//scroll down
	  		if($(document).scrollTop() > 50) {
	      		if($('.header-container').data('size') === 'big') {
	         		$('.header-container').data('size','small');
	          		$('.header-container').stop().animate({
	              		//marginTop:'-100px'
						height: '40px'
	          		},300, 'easeInOutCubic');
	         		$('img.site-logo').data('size','small');
	          		$('img.site-logo').stop().animate({
	              		width:'170px',
	  					//marginTop:'100px'
	          		},300, 'easeInOutCubic');
					$('nav.nav-header').data('size','small');
	          		$('nav.nav-header').stop().animate({
	              		marginTop:'10px',
	          		},300, 'easeInOutCubic');
					$('.sidebar-header').data('size','small');
	          		$('.sidebar-header').stop().animate({
	              		marginTop:'-40px',
	          		},300, 'easeInOutCubic');
					$('.menu-header-container').data('size','small');
	          		$('.menu-header-container').stop().animate({
						marginTop:'-4px',
	              		borderWidth: 0,
	          		},300, 'easeInOutCubic');
	         		$('.navbar-toggle').data('size','small');
	          		$('.navbar-toggle').stop().animate({
	              		marginTop:'4px',
						borderWidth: 0,
						opacity: 0 
	  				},300, 'easeInOutCubic');
	      		}
	  		}
			
			//scroll back up
	  		else {
	  			if($('.header-container').data('size') === 'small'){
	          		$('.header-container').data('size','big');
	          		$('.header-container').stop().animate({
	              		//marginTop:'0'
						height: '65px' //should correspond with css
	          		},300, 'easeInOutCubic');
	          		$('img.site-logo').data('size','big');
	          		$('img.site-logo').stop().animate({
	              		width:'300px', //should correspond with css
	  					//marginTop:'0'
	          		},300, 'easeInOutCubic');
					$('nav.nav-header').data('size','big');
	          		$('nav.nav-header').stop().animate({
	              		marginTop:'25px', //should correspond with css
	          		},300, 'easeInOutCubic');
					$('.sidebar-header').data('size','big');
	          		$('.sidebar-header').stop().animate({
	              		marginTop:'0',
	          		},300, 'easeInOutCubic');
					$('.menu-header-container').data('size','big');
	          		$('.menu-header-container').stop().animate({
						marginTop:'-9px',
	              		borderWidth: 1,
	          		},300, 'easeInOutCubic');
	         		$('.navbar-toggle').data('size','big');
	          		$('.navbar-toggle').stop().animate({
	              		marginTop:'15px',
						borderWidth: 1,
						opacity: 1 
	  				},300, 'easeInOutCubic');
	  			}
	  		} 
	    	
	  	});
		
		// Get & Set widths and heights dynamically
	    
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
  		
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
  
  

})(jQuery); // Fully reference jQuery after this point.
